import { UsersTypes, UserState, UserActionTypes } from "./types";
import { Reducer } from "redux";

const initialState: UserState = {
  inPromise: false,
  isLogged: null, // Estado inicial desconocido
  isLoggedOut: false, // Indica si el usuario cerró sesión exitosamente
  loginLoading: false,
  checkingAuth: false, // Indica si se está verificando la autenticación
  checkAuthFail: false, // Indica si falló la autenticación
  checkAuthSuccess: false, // Indica si la autenticación fue exitosa
  tipo: "",
  idEdificioCopropietario: undefined,
  userProfile: {
    tipo: "",
    copropietario: { _id: "", edificio: "" }
  },
  edificios: [],
  edificiosLoad: false,
  message: "",
  errorMessage: "",
  _id: "",
  newContentAvailable: false,
  checkingLogout: false, // Indica si el logout está en proceso
  userLoad: false,
  checkLogged: false,
};

export const userReducer: Reducer<UserState> = (
  state = initialState,
  action: UserActionTypes
) => {
  switch (action.type) {
    case UsersTypes.NEW_CONTENT_AVAILABLE:
      return {
        ...state,
        newContentAvailable: true,
      };
    case UsersTypes.RESET_NEW_CONTENT_AVAILABLE:
      return {
        ...state,
        newContentAvailable: false,
      };
    case UsersTypes.LOGIN_REQUEST:
      return {
        ...state,
        inPromise: true,
        loginLoading: true,
        message: "",
        errorMessage: ""
      };
    case UsersTypes.LOGIN_SUCCESS:
      return {
        ...state,
        tipo: action.payload.user.tipo,
        checkingAuth:false,
        idEdificioCopropietario: action.payload.user.edificioCopropietario,
        isLogged: true,
        checkAuthFail:false,
        loginLoading: false,
        _id: action.payload.user._id,
        inPromise: false,
        message: action.payload.message,
        errorMessage: "",
        userProfile: { ...state.userProfile },
      };
    case UsersTypes.LOGIN_FAIL:
      return {
        ...state,
        inPromise: false,
        errorMessage: action.payload.message,
        isLogged: false,
        loginLoading: false
      };
    case UsersTypes.CHECK_AUTH_STATUS:
      return {
        ...state,
        inPromise: true,
        checkingAuth: true
      };
      case UsersTypes.CHECK_AUTH_SUCCESS:
        return {
          ...state,
          inPromise: false,
          checkingAuth: false,
          checkAuthSuccess: true
        };
    case UsersTypes.CHECK_AUTH_FAIL:
      return {
        ...state,
        inPromise: false,
        checkingAuth: false,
        checkAuthFail:true,
        isLogged: false
      };
    case UsersTypes.GET_USER_REQUEST:
      return { ...state, userLoad: false, inPromise: true };
    case UsersTypes.GET_USER_SUCCESS:
      return {
        ...state,
        userProfile: { ...action.payload.userProfile },
        userLoad: true,
        inPromise: false,
      };
    case UsersTypes.GET_USER_PROFILE_REQUEST:
      return { ...state, inPromise: true };
    case UsersTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: { ...action.payload.userProfile },
        userLoad: true,
        inPromise: false,
      };
    case UsersTypes.GET_USER_EDIFICIOS:
      return {
        ...state,
        inPromise: true,
        edificiosLoad: false,
        message: "",
        errorMessage: ""
      };
    case UsersTypes.SET_USER_EDIFICIOS:
      return {
        ...state,
        edificios: action.payload.edificios,
        inPromise: false,
        isLoad: true
      };
    case UsersTypes.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      };
    case UsersTypes.LOGOUT:
      return {
        ...state,
        checkAuthFail:true,
        isLogged:false,
        checkingLogout: true
      };
      case UsersTypes.LOGOUT_SUCCESS:
        return {
          ...initialState, 
        };
    default:
      return state;
  }
};
