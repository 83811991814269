import { jwtDecode } from "jwt-decode";



const getUserId = () => {
  const id = localStorage.getItem("userId");
  if (id !== null) return id
  else return "";
};



const isTokenExpired = () => {
  const expirationTime = localStorage.getItem("expirationTime");

  if (!expirationTime) return null; // Si no hay datos, asumimos que el token expiró

  return Date.now() >= parseInt(expirationTime, 10);
};


export {  isTokenExpired, getUserId};
