import React, { useEffect } from "react";
import {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonHeader,
    IonToolbar,
    useIonRouter,
} from "@ionic/react";
import { homeOutline, personOutline, logOutOutline } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "../../utils/auth";
import { logout, logoutSuccess } from "../../store/ducks/users/actions";
import { RootState } from "../../store/ducks/rootReducer";

const MainMenu: React.FC = () => {
    const navigation = useIonRouter();
     const { isLogged } = useSelector((state: RootState) => state.users);
    const dispatch = useDispatch();

    function salir() {
        dispatch(logout());
       // navigation.push("/", "root", "replace");
              
    }
    
  

    return (
        <IonMenu contentId="main-content" side="start">
            <IonHeader className="ion-no-border border-header">
                <IonToolbar className="menu-toolbar d-flex">
                    <div className="ion-logo-splitPane"></div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem routerLink="/home" routerDirection="root">
                        <IonIcon icon={homeOutline} slot="start" />
                        <IonLabel>Inicio</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/perfil" routerDirection="forward">
                        <IonIcon icon={personOutline} slot="start" />
                        <IonLabel>Perfil</IonLabel>
                    </IonItem>
                    <IonItem
                        button
                        onClick={() => {
                            salir()
                        }}
                    >
                        <IonIcon icon={logOutOutline} slot="start" />
                        <IonLabel>Salir</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default MainMenu;
