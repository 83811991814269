import { IEdificio } from "../edificios/types";

/**
 * Action types
 */
export enum UsersTypes {
  /*LOGIN*/
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAIL = "LOGIN_FAIL",
  /*GET USER BY ID*/
  GET_USER_REQUEST = "GET_USER_REQUEST",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  /*GET USER PROFILE*/
  GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS",
  /*GET USER EDIFICIOS*/
  GET_USER_EDIFICIOS = "GET_USER_EDIFICIOS",
  SET_USER_EDIFICIOS = "SET_USER_EDIFICIOS",
  /*LOGOUT*/
  LOGOUT = "LOGOUT",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGGED = "LOGGED",
  LOGGED_SUCCESS = "LOGGED_SUCCESS",
  CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE",
  CHECK_AUTH_STATUS = "CHECK_AUTH_STATUS",
  CHECK_AUTH_SUCCESS="CHECK_AUTH_SUCCESS",
  CHECK_AUTH_LOADING = "CHECK_AUTH_LOADING",
  NEW_CONTENT_AVAILABLE = 'NEW_CONTENT_AVAILABLE',
  RESET_NEW_CONTENT_AVAILABLE = 'RESET_NEW_CONTENT_AVAILABLE',
  CHECK_AUTH_FAIL = 'CHECK_AUTH_FAIL',
  RESET_STATE = "RESET_STATE",
}
/**
 * Data types
 */
export interface IUserRequest {
  username: string;
  password: string;
}

export interface IUserSuccess {
  _id: string;
  tipo: string;
  edificioCopropietario?:string;
}
export interface IUserCopropietario {
  _id: string;
  edificio?: string;
}
export interface IUserProfile {
  username: string;
  email: string;
  roles: { _id: string; name: string }[];
  tipo: string | undefined;
  _id: string;
  tecnico?: string;
  administracion?: string;
  copropietario?: IUserCopropietario;
  empresa?: string;
}

export interface ICopropietarioEdificio {
  edificio: string | null;
}
/**
 * State type
 */
export interface UserState {
  inPromise: boolean;
  userLoad: boolean;
  checkAuthFail: boolean;
  checkAuthSuccess:boolean;
  loginLoading: boolean;
  checkingLogout:boolean;
  tipo: string;
  idEdificioCopropietario?:string;
  userProfile: IUserProfile | { tipo: string | undefined, copropietario: IUserCopropietario | undefined};
  edificios: IEdificio[] | [];
  edificiosLoad: boolean;
  message: string;
  errorMessage: string;
  _id: string;
  isLogged: boolean | null;
  isLoggedOut: boolean | null;
  checkingAuth : boolean;
  checkLogged: boolean;
  newContentAvailable: boolean
}
/**
 * Action type
 */
export interface LoginRequestAction {
  type: typeof UsersTypes.LOGIN_REQUEST;
  payload: { user: IUserRequest };
}
export interface LoginSuccessAction {
  type: typeof UsersTypes.LOGIN_SUCCESS;
  payload: { user: IUserSuccess; message: string };
}
export interface LoginFailAction {
  type: typeof UsersTypes.LOGIN_FAIL;
  payload: { message: string };
}
export interface CheckAuthRequestAction {
  type: typeof UsersTypes.CHECK_AUTH_STATUS;
}
export interface CheckAuthSuccessAction {
  type: typeof UsersTypes.CHECK_AUTH_SUCCESS;
  payload: { user: IUserSuccess; message: string };
}

export interface CheckAuthFailAction {
  type: typeof UsersTypes.CHECK_AUTH_FAIL;
}



export interface GetUserRequestAction {
  type: typeof UsersTypes.GET_USER_REQUEST;
  payload: { id: string };
}

export interface GetUserSuccessAction {
  type: typeof UsersTypes.GET_USER_SUCCESS;
  payload: { userProfile: IUserProfile };
}
export interface GetUserRequestAction {
  type: typeof UsersTypes.GET_USER_REQUEST;
  payload: { id: string };
}

export interface GetUserProfileSuccesAction {
  type: typeof UsersTypes.GET_USER_PROFILE_SUCCESS;
  payload: { userProfile: IUserProfile };
}
export interface GetUserProfileRequestAction {
  type: typeof UsersTypes.GET_USER_PROFILE_REQUEST;
}

export interface GetUserProfileSuccessAction {
  type: typeof UsersTypes.GET_USER_SUCCESS;
  payload: { userProfile: IUserProfile };
}

export interface LogoutAction {
  type: typeof UsersTypes.LOGOUT;
}
export interface LogoutSuccessAction {
  type: typeof UsersTypes.LOGOUT_SUCCESS;
}
export interface SetNewContentAvailableAction {
  type: typeof UsersTypes.NEW_CONTENT_AVAILABLE;
}
export interface ResetNewContentAvailableAction {
  type: typeof UsersTypes.RESET_NEW_CONTENT_AVAILABLE;
}

export interface CheckAuthLoadingAction {
  type: typeof UsersTypes.CHECK_AUTH_LOADING;
}
export interface LoggedAction {
  type: typeof UsersTypes.LOGGED;
  payload: { _id: string };
}

export interface LoggedSuccessAction {
  type: typeof UsersTypes.LOGGED_SUCCESS;
  payload: string;
}

export interface GetUserEdificiosAction {
  type: typeof UsersTypes.GET_USER_EDIFICIOS;
}
export interface ClearErrorMessageAction {
  type: typeof UsersTypes.CLEAR_ERROR_MESSAGE;
}
export interface SetEdificiosAction {
  type: typeof UsersTypes.SET_USER_EDIFICIOS;
  payload: { edificios: [IEdificio] };
}
export interface ResetStateAction {
  type: typeof UsersTypes.RESET_STATE;
}
export type UserActionTypes =
  | LoginRequestAction
  | LoginSuccessAction
  | CheckAuthRequestAction
  | CheckAuthSuccessAction
  | LogoutAction
  | LogoutSuccessAction
  | LoggedAction
  | LoggedSuccessAction
  | LoginFailAction
  | GetUserRequestAction
  | GetUserSuccessAction
  | SetNewContentAvailableAction
  | ResetNewContentAvailableAction
  | CheckAuthFailAction
  | CheckAuthLoadingAction
  | GetUserProfileRequestAction
  | GetUserProfileSuccesAction
  | ClearErrorMessageAction
  | SetEdificiosAction
  | GetUserEdificiosAction
  | ResetStateAction;