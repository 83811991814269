import { Reducer } from "redux";
import { ReporteTypes, ReporteState, ReporteActionTypes } from "./types";
import { ReportesScopes } from "../../../utils/reportesScope";

const initialState: ReporteState = {
  reporteByIdLoading: false,
  reporteNuevoLoading: false,
  reportesLoading: false,
  reporteById: null,
  reporteByIdFail: false,
  reporteNuevo: null,
  reportesByEdificio: [],
  reportesUnread: [],
  message: "",
  errorMessage: "",
  scope: ""
};

export const reporteReducer: Reducer<ReporteState> = (
  state = initialState,
  action: ReporteActionTypes
) => {
  switch (action.type) {
    case ReporteTypes.POST_REPORTE_REQUEST:
      return {
        ...state,
        errorMessage: "",
        reporteNuevoLoading: true,
        scope: ReportesScopes.CREAR_REPORTE,
        message: ""
      };
    case ReporteTypes.POST_REPORTE_SUCCESS:
      return {
        ...state,
        reporteNuevoLoading: false,
        reporteNuevo: action.payload.reporte,
        scope: action.payload.scope,
        message: action.payload.message,
        errorMessage: ""
      };
    case ReporteTypes.POST_REPORTE_FAIL:
      return {
        ...state,
        reporteNuevoLoading: false,
        message: "",
        errorMessage: action.payload.errorMessage,
        scope: action.payload.scope
      };

    case ReporteTypes.GET_REPORTES_BY_EDIFICIO:

      return {
        ...state,
        reportesLoading: true,
        message: "",
        errorMessage: ""
      };

    case ReporteTypes.SET_REPORTES_BY_EDIFICIO:
      return {
        ...state,
        reportesByEdificio: action.payload.reportes,
        reportesLoading: false,
        message: action.payload.message,
        errorMessage: "",
        scope: action.payload.scope
      };

    case ReporteTypes.SET_REPORTES_BY_EDIFICIO_FAIL:
      return {
        ...state,
        reportesLoading: false,
        message: "",
        errorMessage: action.payload.errorMessage,
        scope: action.payload.scope
      };
    case ReporteTypes.GET_REPORTE_BY_ID_REQUEST:
      return {
        ...state,
        reporteById: null,
        reporteByIdFail: false,
        reporteByIdLoading: true,
        errorMessage: "",
        message: "",
        scope: ReportesScopes.DETALLE_REPORTE
      };
    case ReporteTypes.GET_REPORTE_BY_ID_SUCCESS:
      return {
        ...state,
        reporteByIdLoading: false,
        reporteById: action.payload.reporte,
        message: action.payload.message,
        scope: ReportesScopes.DETALLE_REPORTE
      };
    case ReporteTypes.GET_REPORTE_BY_ID_FAIL:
      return {
        ...state,
        reporteByIdFail: true,
        reporteByIdLoading: false,
        errorMessage: action.payload.errorMessage,
        scope: ReportesScopes.DETALLE_REPORTE
      };
    case ReporteTypes.DELETE_REPORTE_REQUEST:
      return {
        ...state,
        errorMessage: "",
        message: "",
        scope: action.payload.scope, // Guarda el scope desde el componente para identificar el contexto
      };
    case ReporteTypes.DELETE_REPORTE_SUCCESS:
      if (action.payload.scope === ReportesScopes.DETALLE_REPORTE) {
        return {
          ...state,
          reporteById: null, // Limpia el detalle del reporte si está en el contexto del detalle
          message: action.payload.message, // Mensaje de éxito
          errorMessage: "",
        };
      } else if (action.payload.scope === ReportesScopes.LISTA_REPORTES) {
        return {
          ...state,
          reportesByEdificio: state.reportesByEdificio.filter(
            (reporte) => reporte._id !== action.payload.id // Filtra el reporte eliminado
          ),
          message: action.payload.message, // Mensaje de éxito
          errorMessage: "",
        };
      } else {
        return {
          ...state,
          message: action.payload.message, // Mensaje de éxito
          errorMessage: "",
        };
      }

    case ReporteTypes.DELETE_REPORTE_FAIL:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        message: "",
        scope: action.payload.scope, // Mantén el scope para identificar el contexto
      };
    case ReporteTypes.CLEAR_MESSAGE:
      return {
        ...state,
        message: "",
        errorMessage: "",
        scope: ""
      };
   
    default:
      return state;
  }
};