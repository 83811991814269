import React, { useState } from "react";
import {
    IonCard,
    IonCardContent,
    IonModal,
    IonButton,
    IonIcon
} from "@ionic/react";
import moment from "moment";
import "moment/locale/es";
import { IReporte } from "../../../store/ducks/reportes/types";
import { closeOutline, searchOutline } from "ionicons/icons";

// Importamos el archivo de estilos
import "./ReporteDetailCard.css";

export type ReporteDetailProps = {
    reporte?: IReporte;
};

const ReporteDetailCard: React.FC<ReporteDetailProps> = ({ reporte }) => {
    const [showImageModal, setShowImageModal] = useState(false);

    const handleImageClick = () => {
        setShowImageModal(true);
    };

    const closeImageModal = () => {
        setShowImageModal(false);
    };

    return (
        <>
            <IonCard className="ion-card-descripcion">
                <IonCardContent className="ion-card-descripcion-content">
                    <div className="mt-3 mb-3">
                        {reporte?.titulo && (
                            <div>
                                <h4 className="font-dark-grey font-weight-600 text-capitalize card-detail-title">{reporte.titulo}</h4>
                               
                            </div>
                        )}
                        
                        {reporte?.sector && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Sector</h4>
                                <p>{reporte.sector}</p>
                            </div>
                        )}
                        
                        {reporte?.tipoDesperfecto && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Categoría</h4>
                                <p>{reporte.tipoDesperfecto}</p>
                            </div>
                        )}
                        

                        {reporte?.contenido && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Descripción</h4>
                                <p>{reporte.contenido}</p>
                            </div>
                        )}
                        {reporte?.prioridad && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Prioridad</h4>
                                <p className="text-justify text-capitalize">{reporte.prioridad}</p>
                            </div>
                        )}
                        {reporte?.estado && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Estado</h4>
                                <p className="text-justify text-capitalize">{reporte.estado}</p>
                            </div>
                        )}

                        {reporte?.evaluacionTecnica !== undefined && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Evaluación Técnica</h4>
                                <p>{reporte.evaluacionTecnica ? "Sí" : "No solicitada"}</p>
                            </div>
                        )}

                       
                        {reporte?.imgUrl && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Imagen</h4>

                                {/* Contenedor para la imagen y el ícono */}
                                <div className="container-img-with-icon mt-1">
                                    <img
                                        src={reporte.imgUrl}
                                        alt="Imagen del reporte"
                                        className="reporte-img"
                                        onClick={handleImageClick}
                                    />
                                    <IonIcon
                                        icon={searchOutline}
                                        className="reporte-img-lupa"
                                    />
                                </div>
                            </div>
                        )}
                         {reporte?.createdAt && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">Publicación</h4>
                                <p>
                                    {moment(reporte.createdAt).locale("es").format("LL")}
                                </p>
                            </div>
                        )}

                        {reporte?.updatedAt && (
                            <div className="mt-3">
                                <h4 className="font-dark-grey mb-1 font-weight-500">
                                    Última actualización
                                </h4>
                                <p>
                                    {moment(reporte.updatedAt).locale("es").format("LL")}
                                </p>
                            </div>
                        )}
                    </div>
                </IonCardContent>
            </IonCard>

            {/* Modal para mostrar la imagen en pantalla completa */}
            {reporte?.imgUrl && (
                <IonModal isOpen={showImageModal} onDidDismiss={closeImageModal} className="img-custom-modal">
                    <div className="image-modal-container">
                        <IonButton
                            className="close-image-button"
                            fill="clear"
                            onClick={closeImageModal}
                        >
                            <IonIcon icon={closeOutline} size="large" />
                        </IonButton>
                        <img
                            src={reporte.imgUrl}
                            alt="Imagen del reporte en pantalla completa"
                            className="modal-img"
                        />
                    </div>
                </IonModal>
            )}
        </>
    );
};

export default React.memo(ReporteDetailCard);
