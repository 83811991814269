import { Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/ducks/rootReducer";
import CenteredLoader from "../components/CenteredLoader/CenteredLoader";


const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isLogged, checkingAuth, checkingLogout, loginLoading, } = useSelector(
    (state: RootState) => state.users
  );

  return (
    <>
      {checkingAuth || checkingLogout || loginLoading ? (
        <CenteredLoader isOpen={true} message="Verificando autenticación..." />
      ) : isLogged ? (
        <Route {...rest} component={Component} />
      ) : <CenteredLoader isOpen={true} message="Cargando..." />
      }
    </>
  );
};

export default PrivateRoute;

