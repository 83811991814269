import React, { useEffect, useState, Suspense, useCallback } from 'react';
import { IonToast, IonRouterOutlet, useIonRouter, IonApp, IonSpinner, useIonViewWillEnter, useIonViewDidEnter } from "@ionic/react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Route } from "react-router-dom";
import LoginRoute from "../routes/LoginRoute";
import PrivateRoute from "../routes/PrivateRoute";
import CenteredLoader from '../components/CenteredLoader/CenteredLoader';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuthStatus, getUserProfileRequest, loginFail, logout, logoutSuccess, resetNewContentAvailable, resetState } from '../store/ducks/users/actions';
import { App as CapacitorApp } from '@capacitor/app';
import { RootState } from '../store/ducks/rootReducer';
import Login from './Login/Login';
import MenuCopropietario from './MenuCopropietario/MenuCopropietario';
import { getUserId, isTokenExpired } from '../utils/auth';
import ObrabitPresentation from './ObrabitPresentacion/ObrabitPresentacion';
import { getEdificioCopropietario } from '../store/ducks/edificios/actions';
import InformeCopList from './InformesCopropietario/InformeCopList';
import InformeCopDetail from './InformesCopropietario/InformeCopDetail';
import InformeCopArchivos from './InformesCopropietario/InformeCopArchivos';
import InformeCopMemorias from './InformesCopropietario/InformeCopMemorias';
import InformeCopNotas from './InformesCopropietario/InformeCopNotas';
import InformeCopObras from './InformesCopropietario/InformeCopObras';
import InformeCopPresupuestos from './InformesCopropietario/InformeCopPresupuestos';

import PoliticaPrivacidad from './PoliticaPrivacidad/PoliticaPrivacidad';
import ReportesCopCreate from './ReportesCopropietarios/ReportesCopCreate';
import ModalImagen from '../components/ModalImagen/ModalImagen';
import TabsCopropietario from './TabsCopropietario/TabsCopropietario';
import { checkAuth } from '../api/usersAuth';
// Carga diferida
//const MenuCopropietario = React.lazy(() => import('./MenuCopropietario/MenuCopropietario'));
const Main: React.FC = () => {
    const { isLogged, tipo, loginLoading, idEdificioCopropietario, newContentAvailable, checkAuthFail, isLoggedOut, checkingAuth, checkingLogout, userProfile, checkAuthSuccess } = useSelector((state: RootState) => state.users);
    const [profileLoaded, setProfileLoaded] = useState(false);
    const [toastIsOpen, setToastIsOpen] = useState(false);
    const [lastCheckedPath, setLastCheckedPath] = useState<string | null>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigation = useIonRouter();
    const history = useHistory();

    const pathByType = (tipo: string | "") => {

        if (tipo === "copropietario") return "/copropietario/tabs";
        if (tipo === "gestor") return "/administracion/tabs";
        if (tipo === "tecnico") return "/tecnico/tabs";
        return "/";
    };

    const userId = getUserId();
    useEffect(() => {
        if (isTokenExpired()) {
            console.log("CHECK PRIVATE TOKEN EXPIRED", isTokenExpired());
            dispatch(logout());
        };

    }, [isTokenExpired(), location.pathname]);

    useEffect(() => {

        console.log("en main antes del checking", location.pathname, pathByType(tipo), isLogged, checkingAuth, loginLoading, isTokenExpired());


        if ((isLogged === false || (!isLogged && userId === "")) && location.pathname !== "/" && !checkingAuth) {
            console.log("REDIRECT LOGIN");
            history.replace("/");

        };
        
        if (!isLogged && !checkingAuth && !checkingLogout && !loginLoading && userId !== "") {
            console.log("CHECK PRIVATE");
            dispatch(checkAuthStatus());
        };

        console.log("main antes del redirect", location.pathname, pathByType(tipo), isLogged, checkingAuth, loginLoading, userId);
        if ((isLogged) && location.pathname === "/") {
            console.log("REDIRECT PRIVATE");
            navigation.push(`${pathByType(tipo)}`);
        };



    }, [isLogged]);



    useEffect(() => {
        const backButtonListener = CapacitorApp.addListener('backButton', ({ canGoBack }) => {
            if (!canGoBack) {
                // En la página de inicio, salir de la app
                CapacitorApp.exitApp();
            }
        });
        return () => {
            // Asegurarse de limpiar el listener al desmontar el componente
            backButtonListener.remove();
        };
    }, []);

    const reloadPage = useCallback(() => {

        window.location.reload();
    }, []);

    const handleReload = () => {
        setToastIsOpen(false);
        dispatch(resetNewContentAvailable());
        reloadPage();
    };




    useEffect(() => {
        // If profile is not loaded, request it
        if (location.pathname !== '/') {
            if (newContentAvailable) {
                setToastIsOpen(true);
            }
        }
    }, [newContentAvailable, location]);

    useEffect(() => {
        if (!profileLoaded && isLogged) {
            dispatch(getUserProfileRequest());
            setProfileLoaded(true);
        } else if (!isLogged) {
            setProfileLoaded(false);
        }
    }, [isLogged, profileLoaded]);


    return (

        <>
            <IonRouterOutlet>
                <Route exact path="/servicios" component={ObrabitPresentation} />
                <Route exact path="/privacidad" component={PoliticaPrivacidad} />
                <LoginRoute exact path="/" component={Login} userHomePath={pathByType(tipo)} />


                <PrivateRoute path="/copropietario/tabs" component={TabsCopropietario} />

                <PrivateRoute
                    exact path="/copropietario/informes/:idEdificio/lista"
                    component={InformeCopList}
                />
                <PrivateRoute
                    exact path="/copropietario/informes/:idEdificio/lista/:informeId"
                    component={InformeCopDetail}
                />
                <PrivateRoute
                    path="/copropietario/informes/:idEdificio/lista/archivos/:informeId"
                    component={InformeCopArchivos}
                />
                <PrivateRoute
                    path="/copropietario/informes/:idEdificio/lista/presupuestos/:informeId"
                    component={InformeCopPresupuestos}
                />
                <PrivateRoute
                    path="/copropietario/informes/:idEdificio/lista/obras/:informeId"
                    component={InformeCopObras}
                />
                <PrivateRoute
                    path="/copropietario/informes/:idEdificio/lista/notas/:informeId"
                    component={InformeCopNotas}
                />
                <PrivateRoute
                    path="/copropietario/informes/:idEdificio/lista/memorias/:informeId"
                    component={InformeCopMemorias}
                />
            </IonRouterOutlet>

            <IonToast
                isOpen={toastIsOpen}
                message="Una nueva actualización de la aplicación se encuentra disponible. Acepte para continuar."
                onDidDismiss={() => setToastIsOpen(false)}
                layout="stacked"
                buttons={[
                    {
                        text: 'Aceptar',
                        handler: handleReload
                    },
                    {
                        text: 'Cancelar',
                        role: 'cancel'
                    }
                ]}
            />
        </>


    );
};

export default Main;