import { Reducer } from "redux";
import { EdificioTypes, EdificioState } from "./types";

const initialState: EdificioState = {
  isLoad: false,
  inPromise: false,
  edificios: undefined,
  edificiosUnread: undefined,
  message: "",
  errorMessage: "",
  edificioNuevo: {},
  edificioById: {},
  edificioCopropietario: {},
  informes: undefined
};

export const edificioReducer: Reducer<EdificioState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case EdificioTypes.GET_EDIFICIOS:
      return {
        ...state,
        inPromise: true,
        isLoad: false,
        message: "",
        errorMessage: ""
      };
    case EdificioTypes.SET_EDIFICIOS:
      return {
        ...state,
        edificios: action.payload.edificios,
        inPromise: false,
        isLoad: true
      };
    case EdificioTypes.GET_EDIFICIOS_UNREAD:
      return {
        ...state,
        inPromise: true,
        message: "",
        errorMessage: ""
      };
    case EdificioTypes.SET_EDIFICIOS_UNREAD:
      return {
        ...state,
        edificiosUnread: action.payload.edificios,
        inPromise: false
      };
    case EdificioTypes.GET_EDIFICIO_BY_ID:
      return {
        ...state,
        inPromise: true,
        isLoad: false,
        message: "",
        errorMessage: ""
      };
    case EdificioTypes.SET_EDIFICIO_BY_ID:
      return {
        ...state,
        edificioById: action.payload.edificio,
        inPromise: false,
        isLoad: true
      };
      case EdificioTypes.GET_EDIFICIO_INFORMES:
      return {
        ...state,
        inPromise: true,
        isLoad: false,
        message: "",
        errorMessage: ""
      };
      case EdificioTypes.SET_EDIFICIO_INFORMES:
      return {
        ...state,
        informes: action.payload.informes,
        inPromise: false
      };
    case EdificioTypes.GET_EDIFICIO_COPROPIETARIO:
      return {
        ...state,
        inPromise: true,
        isLoad: false,
        message: "",
        errorMessage: ""
      };
      case EdificioTypes.SET_EDIFICIO_COPROPIETARIO:
        console.log("✅ Reducer: SET_EDIFICIO_COPROPIETARIO ejecutado", action.payload);
        return {
          ...state,
          edificioCopropietario: action.payload.edificio,
        };
    case EdificioTypes.POST_EDIFICIO_REQUEST:
      return {
        ...state,
        inPromise: true
      };
    case EdificioTypes.POST_EDIFICIO_SUCCESS:
      return {
        ...state,
        inPromise: false,
        edificioNuevo: action.payload.edificio,
        message: action.payload.message
      };
    case EdificioTypes.POST_EDIFICIO_FAIL:
      return {
        ...state,
        inPromise: false,
        message: action.payload.message
      };
    case EdificioTypes.SET_EDIFICIO_ERROR_MESSAGE:
      return {
        ...state,
        inPromise: false,
        errorMessage: action.payload.message
      };
    
    default:
      return state;
  }
};
