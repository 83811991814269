import { action } from "typesafe-actions";
import { UsersTypes, IUserRequest, IUserSuccess, IUserProfile } from "./types";
import { IEdificio } from "../edificios/types";
import { checkAuth } from "../../../api/usersAuth";

export const logInRequest = (user: IUserRequest) =>
  action(UsersTypes.LOGIN_REQUEST, { user });
/* export const logInRequest = (user: FormData) =>
  action(UsersTypes.LOGIN_REQUEST, { user }); */
export const logInSuccess = (user: IUserSuccess, message: string) =>
  action(UsersTypes.LOGIN_SUCCESS, { user, message });

export const getUserRequest = (id: string) =>
  action(UsersTypes.GET_USER_REQUEST, { id });

export const getUserSuccess = (userProfile: IUserProfile) =>
  action(UsersTypes.GET_USER_SUCCESS, { userProfile });

export const getUserProfileRequest = () =>
  action(UsersTypes.GET_USER_PROFILE_REQUEST);

export const getUserProfileSuccess = (userProfile: IUserProfile) =>
  action(UsersTypes.GET_USER_PROFILE_SUCCESS, { userProfile });

export const getUserEdificios = () => action(UsersTypes.GET_USER_EDIFICIOS);
export const setUserEdificios = (edificios: [IEdificio]) =>
  action(UsersTypes.SET_USER_EDIFICIOS, { edificios });

export const logout = () => action(UsersTypes.LOGOUT);
export const logoutSuccess = () => action(UsersTypes.LOGOUT_SUCCESS);
export const checkAuthFail = () => action(UsersTypes.CHECK_AUTH_FAIL);

export const loginFail = (message: string) =>
  action(UsersTypes.LOGIN_FAIL, { message });

export const checkAuthStatus = () => action(UsersTypes.CHECK_AUTH_STATUS);
export const checkAuthSuccess = (user: IUserSuccess, message: string) => action(UsersTypes.CHECK_AUTH_SUCCESS, { user, message });

export const clearErrorMessage = () =>
  action(UsersTypes.CLEAR_ERROR_MESSAGE)
export const setNewContentAvailable = () =>
  action(UsersTypes.NEW_CONTENT_AVAILABLE)
export const resetNewContentAvailable = () =>
  action(UsersTypes.RESET_NEW_CONTENT_AVAILABLE)
export const resetState = () => action(UsersTypes.RESET_STATE);
const actions = {
  logInRequest: logInRequest,
  logInSuccess: logInSuccess,
  getUserRequest: getUserRequest,
  getUserSuccess: getUserSuccess,
  getUserProfileRequest: getUserProfileRequest,
  getUserProfileSuccess: getUserProfileSuccess,
  getUserEdificios: getUserEdificios,
  setUserEdificios: setUserEdificios,
  loginFail: loginFail,
  logout: logout,
  logoutSuccess: logoutSuccess,
  checkAuthStatus: checkAuthStatus,
  checkAuthSuccess: checkAuthSuccess,
  setNewContentAvailable: setNewContentAvailable,
  resetNewContentAvailable: resetNewContentAvailable,
  checkAuthFail: checkAuthFail,
  resetState: resetState,
};
export { actions as userActions };
