import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  useIonRouter,
  IonAlert,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";
import "../../styles/App.css";
import { RootState } from "../../store/ducks/rootReducer";
import {
  logInRequest,
  getUserProfileRequest,
  clearErrorMessage,
  resetState,
} from "../../store/ducks/users/actions";
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { alertCircleOutline, eye, eyeOff, eyeOffOutline, eyeOutline } from "ionicons/icons";
import { Redirect } from "react-router";
import { getUserId } from "../../utils/auth";
import { getEdificioCopropietario } from "../../store/ducks/edificios/actions";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { _id, userProfile, isLogged, errorMessage, tipo } = useSelector((state: RootState) => state.users);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const clearInputs = () => {
    setUsername("");
    setPassword("");
  };
  const clearError = () => {
    setError("");
    dispatch(clearErrorMessage());
  };


  useEffect(() => {

    if (_id !== "" && userProfile.tipo == "") {
      dispatch(getUserProfileRequest());
    }
    if (isLogged && tipo !== "") {

      setError("");
      dispatch(clearErrorMessage());

    }
  }, [_id, isLogged, tipo]);

  useEffect(() => {
    if (isLogged) {
      clearInputs(); // Limpiar campos al hacer login
    }

  }, [isLogged]);

  useEffect(() => {
    if (errorMessage !== "") {
      setError(errorMessage);
    }
  }, [errorMessage]);

 

  useEffect(() => {
    const usernameInput = document.querySelector('input[name="username"]') as HTMLInputElement;
    const passwordInput = document.querySelector('input[name="password"]') as HTMLInputElement;

    if (usernameInput?.value) {
      setUsername(usernameInput.value); // Actualiza el estado local
      setValue("username", usernameInput.value); // Sincroniza con react-hook-form
    }

    if (passwordInput?.value) {
      setPassword(passwordInput.value); // Actualiza el estado local
      setValue("password", passwordInput.value); // Sincroniza con react-hook-form
    }
  }, [setValue]);
  const onSubmit = (data: any) => {

    dispatch(logInRequest({ username: data.username, password: data.password }));
  };

  useEffect(() => {
    if (username) {
      clearErrors("username");
    }
    if (password) {
      clearErrors("password");
    }
  }, [username, password, clearErrors]);
  useEffect(() => {
   console.log("enlogin");
  }, []);


  return (
    <IonPage>

      {error !== "" && (
        <IonAlert
          isOpen={error !== ""}
          header="Aviso"
          message={errorMessage}
          buttons={[{ text: "OK", handler: clearError }]}
          onDidDismiss={clearError}
        />
      )}

      <IonContent fullscreen className="ion-login-content">


        <IonGrid className="ion-grid-login">
          <IonRow className="ion-justify-content-center ion-row-login">
            <IonCol className="col-login-form" size-md="6" size-lg="5" size-xs="12">
              <div className="ion-padding align-center-logo-login">
                <div className="ion-logo-login"></div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="ion-padding">
                  <IonInput
                    id="login-form-input-name"
                    className="input-login"
                    {...register("username", { required: "Ingresa tu nombre de usuario" })}
                    placeholder="Ingrese su nombre de usuario"
                    aria-label="Nombre de usuario"
                    fill="outline"
                    labelPlacement="stacked"
                    shape="round"
                    value={username}
                    onIonChange={(e) => {
                      const value = e.detail.value!;
                      setUsername(value); // Actualiza el estado local
                      setValue("username", value); // Sincroniza con react-hook-form
                    }}
                    onFocus={() => {
                      // Captura valores autocompletados al enfocar
                      const input = document.querySelector('input[name="username"]') as HTMLInputElement;
                      if (input?.value) setValue("username", input.value);
                    }}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="username"
                    render={({ message }) => (
                      <div className="error-form">
                        <IonIcon icon={alertCircleOutline} slot="start" />
                        <span>{message}</span>
                      </div>
                    )}
                  />

                  <IonInput
                    id="login-form-input-password"
                    className="input-login"
                    {...register("password", { required: "Ingresa tu contraseña" })}
                    type={showPassword ? "text" : "password"}
                    placeholder="Ingrese su contraseña"
                    shape="round"
                    aria-label="Contraseña"
                    fill="outline"
                    labelPlacement="stacked"
                    value={password}
                    onIonChange={(e) => {
                      const value = e.detail.value!;
                      setPassword(value); // Actualiza el estado local
                      setValue("password", value); // Sincroniza con react-hook-form
                    }}
                    onFocus={() => {
                      // Captura valores autocompletados al enfocar
                      const input = document.querySelector('input[name="password"]') as HTMLInputElement;
                      if (input?.value) setValue("password", input.value);
                    }}
                  >
                    <IonButton fill="clear" slot="end" onClick={toggleShowPassword} className="ion-button-show-hide">
                      <IonIcon slot="icon-only" icon={showPassword ? eyeOutline : eyeOffOutline} aria-hidden="true" />
                    </IonButton>
                  </IonInput>

                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <div className="error-form">
                        <IonIcon icon={alertCircleOutline} slot="start" />
                        <span>{message}</span>
                      </div>
                    )}
                  />
                </div>

                <div className="ion-padding">
                  <IonButton shape="round" size="default" expand="full" type="submit" className="ion-button-login">
                    Ingresar
                  </IonButton>
                </div>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>

    </IonPage>
  );
};


export default Login;