import { combineReducers } from "redux";
import { userReducer } from "./users";
import { edificioReducer } from "./edificios";
import { obraReducer } from "./obras"
import { informeReducer } from "./informes";
import { visitaReducer } from "./visitas";
import { observacionReducer} from "./observaciones";
import { reporteReducer} from "./reportes";
import { avisoReducer } from "./avisos";
import { UsersTypes } from "../ducks/users/types"; // Importar los tipos de acciones

const appReducer = combineReducers({
  users: userReducer,
  edificios: edificioReducer,
  obras: obraReducer,
  informes: informeReducer,
  visitas: visitaReducer,
  observaciones: observacionReducer,
  reportes: reporteReducer,
  avisos: avisoReducer
});
const rootReducer = (state: any, action: any) => {
  if (action.type === UsersTypes.LOGOUT_SUCCESS) {
    console.log("🚀 Reset Redux State: Logout Success triggered");
    return appReducer(undefined, action); // 🔥 Reinicia todo el estado global
  }

  return appReducer(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;